import '../css/app.css';
import React from 'react';
import { withTranslation } from "react-i18next";
import { jumpToReleventDiv } from './functions'

class Contact extends React.Component {
  constructor(props) {  
      super(props);

    
      this.state = {      
          name: '',
          nameError: false,
          city: '',
          province: '',
          phone: '',
          email: '',
          emailError: false,
          emailError2: false,
          dist: false,
          product: '',
          message: '',
          messageError: false,
          formValid: false,
          emailSuccess: false,
          emailConfirm: false,
          emailSent: false,
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBlur   = this.handleBlur.bind(this); 
  }

  clearForm() {
    this.setState({ name: '' });
    this.setState({ city: '' });
    this.setState({ province: '' });
    this.setState({ phone: '' });
    this.setState({ email: '' });
    this.setState({ product: '' });
    this.setState({ dist: false });
    this.setState({ message: '' });

    document.getElementById("dist").checked = false;
    document.getElementById("product").value = "";
  }

  sendMail = async () => {
    console.log("Hello from sendmail")
    this.setState({ emailConfirm: false });
    this.setState({ emailSent: true });
    const { name, city, province, phone, email, product, dist, message } = this.state;

    let details = {
        'name': name,
        'city': city,
        'province': province,
        'phone': phone,
        'email': email,
        'product': product,
        'dist': dist === true ? this.props.t("contact_dist") : "",
        'message': message,
        'subject': this.props.t("contact_subject") 
    };

    console.log("before let response")
    let response = await fetch("/server/contact", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
    });

    let result = await response.json();

    console.log("got result")
    this.setState({ emailConfirm: true });
    this.setState({ emailSuccess: result['status']==='Message Sent' });
    this.setState({ emailSent: false });

    if(result['status']==='Message Sent'){
      /*window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smoothly scrolling
      });*/

      this.clearForm();
      jumpToReleventDiv("contact")
      setTimeout(function(){
        this.setState({ emailConfirm: false });
      }.bind(this),5000);
    }
    else{
      jumpToReleventDiv("contact")
    }
};

  isValidEmail(email) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  handleBlur(e) {
    const name = e.target.name; 
    const value = e.target.value;

    if(name==="product") e.target.className = e.target.value === "" ? "Contact-product empty" : "Contact-product selected"

    if(name==="dist"){
      this.setState({ [name]: e.target.checked});
    }
    else{
      this.setState({ [name]: value });
    }
  }

  handleChange(e) {
    const name = e.target.name; 

    if(name==="product") e.target.className = e.target.value === "" ? "Contact-product empty" : "Contact-product selected"
    if(name==="product") e.target.className = e.target.value === "" ? console.log("Contact-product empty") : console.log("Contact-product- selected")

    if(name==="dist"){
      this.setState({ [name]: e.target.checked});
    }
    else{
      this.setState({ [name]: e.target.value});
      this.setState({ emailSent: false });
    }
    
  }

  validForm(){
      let { name, email, message, dist } = this.state;

      console.log(dist);

      if (name.length <= 0) {
        this.setState({ nameError: true });
        this.asErr = true;
        console.log("errName");
      } else {
        console.log("nameOK");
        this.setState({ nameError: false });
      };

      if (email.length <= 0) {
        this.setState({ emailError: true });
        this.setState({ emailError2: false });
        this.asErr = true;
        console.log("errEmail");
      } else {
        this.setState({ emailError: false });
        if(this.isValidEmail(email)) {
          console.log("emailOK");
          this.setState({ emailError2: false });  
        } else {
          this.setState({ emailError2: true }); 
          console.log("errEmail2");
          this.asErr = true; 
        }
      }

      if (message.length <= 0) {
        this.setState({ messageError: true });
        this.asErr = true;
        console.log("errMsg");
      } else {
        this.asErr = false;
        this.setState({ messageError: false });
        console.log("msgOK");
      };

  }

  handleSubmit(e) {

    document.getElementById("btnSend").style.visibility = "hidden";

    console.log("Valid email");

    e.preventDefault();

    this.validForm();

    console.log("asErr?");
    if (!this.asErr) {
      this.setState({ formValid: true });

      //setStatus("Sending...");
      console.log("sendMail");      
      this.sendMail();
      
    } else {
      console.log("formValid false");
      this.setState({ formValid: false });
    }

    console.log("exit handle submit");
    /*e.preventDefault(); */
    document.getElementById("btnSend").style.visibility = "visible";
  }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
      window.removeEventListener('scroll', this.handleScroll);
    } 

    handleScroll = () => {

      /*this.setState({ emailConfirm: false });*/
    }


  render() {
    const { nameError, emailError, emailError2, messageError } = this.state;
  return (
    <div className='Contact'>
        <h3>{this.props.t("contact_interest")}</h3>
      <div className='Contact-message sent' style={{display:  this.state.emailConfirm & this.state.emailSuccess ? 'block' : 'none'}}>{this.props.t("contact_message_sent")}</div>
      <div className='Contact-message error' style={{display: this.state.emailConfirm & !this.state.emailSuccess ? 'block' : 'none'}}>{this.props.t("contact_message_error")}</div>
      <div  >
        <form action="/" onSubmit={(e) => this.handleSubmit(e)} encType="multipart/form-data" autoComplete="new-password" >
        <div >
            <input name="name" type="text" placeholder={this.props.t("contact_name")} value={this.state.name} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={(e) => e.target.setAttribute("autoComplete", "new-password")} />
            { nameError
            ? <div className='Contact-misssed'>{this.props.t("contact_name_required")}</div>
            : ''
            }
        </div>
        <div >
            <input name="city" type="text" placeholder={this.props.t("contact_city")} value={this.state.city} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={(e) => e.target.setAttribute("autoComplete", "new-password")} />
        </div>
        <div >
            <input name="province" type="text" placeholder={this.props.t("contact_province")} value={this.state.province} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={(e) => e.target.setAttribute("autoComplete", "new-password")} />
        </div>
        <div >
            <input name="phone" type="text" placeholder={this.props.t("contact_phone")} value={this.state.phone} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={(e) => e.target.setAttribute("autoComplete", "new-password")} />
        </div>
        <div >
            <input name="email" type="text" placeholder={this.props.t("contact_email")} value={this.state.email} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={(e) => e.target.setAttribute("autoComplete", "new-password")} />
            { emailError
            ? <div className='Contact-misssed'>{this.props.t("contact_email_required")}</div>
            : ''
            }
            { emailError2
            ? <div className='Contact-misssed' >{this.props.t("contact_email_invalid")}</div>
            : ''
            }
        </div>
        <div  >
          <select className="Contact-product empty" name="product" id="product" defaultValue="" onChange={this.handleChange} onBlur={this.handleBlur} >
            {/*<option value="" disabled selected className="gray">{this.props.t("contact_select_product")}</option>*/}
            <option key="empty" disabled value="">{this.props.t("contact_product_interest")}</option>
            <option key="nomad" value="Nomad">NOMAD</option>
            <option key="ragnar" value="Ragnar">RAGNAR</option>
            <option key="odin" value="Odin">ODIN</option>
            <option key="acc" value="Accessories">{this.props.t("acc_title")}</option>
          </select>
        </div>
        <div className='Checkbox-row'>
          <div className="checkbox-wrapper-39">
            <label>
                <input name="dist" id="dist" type="checkbox" onChange={this.handleChange} onBlur={this.handleBlur} />
                  <span className="checkbox"></span>
              </label>
          </div>
          <div className='Checkbox-row text'>{this.props.t("contact_dist")}</div>
        </div>
        
        <div contentEditable="true">
            <textarea name="message" type="text" placeholder={this.props.t("contact_message")} value={this.state.message} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={(e) => e.target.setAttribute("autoComplete", "new-password")} />
            { messageError
            ? <div className='Contact-misssed'>{this.props.t("contact_message_required")}</div>
            : ''
            }
        </div>
               <p >
                <input id="btnSend" type="submit" className="App-button" style={{visibility: 'visible'}} value={this.props.t("contact_submit")} />
              </p>
        </form>
      </div>
    </div>
  );
  }
}

export default withTranslation()(Contact);
