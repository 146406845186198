import '../css/app.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { getText } from "./functions";

import background from '../svg/gray_pill_viking.svg';
import background_sm from '../svg/gray_pill.svg';
import vicking from '../svg/vicking.svg';

const divStyle = {
  backgroundImage: `url(${background})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
};

const divStyle_sm = {
  backgroundImage: `url(${background_sm})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
};

class Innovation extends React.Component {

  render() {
  return (
    <div>
      <div className='App-size lg'>
        <div className="Inno" style={divStyle}> 
            <div className="Inno-text">
              <div className='Inno-title'>{getText(this.props.t("inno_title"))}</div>
              <div className='Inno-desc'>{getText(this.props.t("inno_desc"))}</div>
            </div>
        </div>
      </div>
      <div className='App-size sm'>
      <div className="Inno" style={divStyle_sm}> 
        <img style={{width: "75%", marginTop: "calc(var(--margin) * 3)", marginBottom: "var(--margin)"}} src={vicking} alt='v-forge_vicking' />
          <div className="Inno-text">
            <div className='Inno-title'>{getText(this.props.t("inno_title"))}</div>
            <div className='Inno-desc'>{getText(this.props.t("inno_desc"))}</div>
          </div>
      </div>
    </div>
  </div>
  );
}
}

export default withTranslation()(Innovation);
