import '../css/products/ragnar.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { getText, jumpToReleventDiv } from "./functions";

import product_ragnar from '../pics/ragnar_Reflexion.png';

class Ragnar extends React.Component {

  render() {
  return (
     <div >
        <div className='App-size lg'>
          <div className='Product'>
            <div className="Product-name">RAGNAR</div>
              <div className="Product-details Ragnar-details-margin" >
                <img className="Ragnar-image" src={product_ragnar} alt='v-forge_ragnar' />
                <div className="Product-text Ragnar-margin-text" >
                <div className="Product-title">{getText(this.props.t("ragnar_title"))}</div>
                <div className="Product-desc">{getText(this.props.t("ragnar_desc"))}</div>
                <div className="App-button" style={{alignSelf: "flex-start"}} onClick={() => jumpToReleventDiv('contact')} >{getText(this.props.t("common_more"))}</div>
                </div>
                </div> 
            </div>
        </div>
        <div className="App-size sm">
          <div className='Product'>
            <img className="Ragnar-image" src={product_ragnar} alt='v-forge_ragnar' />
            <div className="Product-name">RAGNAR</div>
              <div className="Product-title">{getText(this.props.t("ragnar_title"))}</div>
                <div className="Product-desc">{getText(this.props.t("ragnar_desc"))}</div>
              <div className="App-button" onClick={() => jumpToReleventDiv('contact')} >{getText(this.props.t("common_more"))}</div>
            </div>
        </div>
      </div>
  );
}
}
export default withTranslation()(Ragnar);
