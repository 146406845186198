import React from "react";
import { withTranslation } from "react-i18next";
import { getText, jumpToReleventDiv } from "./functions";

import product_nomad from '../pics/nomad_Reflexion.png';

class Nomad extends React.Component {
  render() {
    return (
      <div >
        <div className="App-size lg">
          <div className='Product'>
            <div className="Product-name">NOMAD</div>
              <div className="Product-details Nomad-details-margin" >
                <div className="Product-text Nomad-margin-text" >
                  <div className="Product-title">{getText(this.props.t("nomad_title"))}</div>
                    <div className="Product-desc">{getText(this.props.t("nomad_desc"))}</div>
                  <div className="App-button" style={{alignSelf: "flex-start"}} onClick={() => jumpToReleventDiv('contact')} >{getText(this.props.t("common_more"))}</div>
                </div>
                <img className="Nomad-image" src={product_nomad} alt='v-forge_nomad' />
              </div> 
            </div>
        </div>
        <div className="App-size sm">
          <div className='Product'>
            <img className="Nomad-image Nomad-details-margin" src={product_nomad} alt='v-forge_nomad' />
            <div className="Product-name">NOMAD</div>
              <div className="Product-title">{getText(this.props.t("nomad_title"))}</div>
                <div className="Product-desc">{getText(this.props.t("nomad_desc"))}</div>
              <div className="App-button" onClick={() => jumpToReleventDiv('contact')} >{getText(this.props.t("common_more"))}</div>
            </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Nomad);
