import '../css/products/odin.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { getText } from "./functions";

import product_odin from '../pics/odin_Reflexion.png';

class Odin extends React.Component {

  render() {
  const jumpToReleventDiv = (id) => {
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    releventDiv.scrollIntoView({behavior: "smooth",  block: "start"});
  }
  return (
    <div >
    <div className='Product'>
      <div className="App-size lg">
        <div className="Product-name">ODIN</div>
          <div className="Product-details Odin-details-margin" >
            <div className="Product-text Odin-margin-text" >
            <div className="Product-title">{getText(this.props.t("odin_title"))}</div>
                <div className="Product-desc">{getText(this.props.t("odin_desc"))}</div>
                <div className="App-button" style={{alignSelf: "flex-start"}} onClick={() => jumpToReleventDiv('contact')} >{getText(this.props.t("common_more"))}</div>
            </div>
            <img className="Odin-image" src={product_odin} alt='v-forge_odin' />
              
          </div> 
        </div>
      </div>
      <div className="App-size sm">
        <div className='Product'>
          <img className="Odin-image" src={product_odin} alt='v-forge_ragnar' />
          <div className="Product-name">ODIN</div>
            <div className="Product-title">{getText(this.props.t("odin_title"))}</div>
              <div className="Product-desc">{getText(this.props.t("odin_desc"))}</div>
            <div className="App-button" onClick={() => jumpToReleventDiv('contact')} >{getText(this.props.t("common_more"))}</div>
          </div>
      </div>
      </div>
  );
}
}
export default withTranslation()(Odin);
