import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./css/app.css";
import Home from './pages/home';

function App() {
  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path="/en" element={<Home language='en'/>} />
            <Route exact path="/" index element={<Home language='fr'/>} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;