import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { getText, jumpToReleventDiv, changeLang } from "../views/functions";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import background from '../pics/background.png';
import logo from '../logo.svg';
import '../css/app.css';

import burger_red from '../svg/burger-menu-red.svg';

import Nomad from '../views/nomad';
import Ragnar from '../views/ragnar';
import Odin from '../views/odin'; 

import Technology from '../views/technology';
import Acc from '../views/accessories';
import Contact from '../views/contact';
import Innovation from '../views/innovation';
import Footer from "../views/footer";

import en from '../lang/en.json';
import fr from '../lang/fr.json';

function Home(props) {
  if(props.language !== i18n.language){
    i18n.changeLanguage(props.language)
  }

  const [logoZoomed, setLogoZoomed] = useState(false);

  const { t } = useTranslation();


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if(window.scrollY === 0){
        setLogoZoomed(false)
      }
      else 
        if(window.scrollY > 25 && logoZoomed === false){
          setLogoZoomed(true);
      }
    });
  }, [logoZoomed]);
 
  const divStyle = {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    /*backgroundSize: '100vw'*/
  };

  function showSmallMenu () {
    document.getElementById('menu').style.visibility='visible'
    document.getElementById('menu').style.height='100vh'
  }

  function hideSmallMenu () {
    document.getElementById('menu').style.visibility='hidden'
    document.getElementById('menu').style.height='0px'
  }

  function clicSmallMenu ( myDiv ) {
    hideSmallMenu()
    if(myDiv !== "lang") {
      jumpToReleventDiv( myDiv )
    }
    else{
      changeLang()
    }
  }
/*
getIpAddress()

function getIpAddress() {

    fetch('https://ipapi.co/json/')
    .then(function(response) {
        response.json().then(jsonData => {
          console.log(jsonData.country, jsonData.region_code)
        });
      })
      .catch(function(error) {
        console.log(error)
      });
}
*/
  return (
    <HelmetProvider>
      <div className="App" style={divStyle} >
        <Helmet>
            <html lang={i18n.language} />
            <title>{t("company_title") + " - " + t("home_title")}</title>
            <meta name="Description" content={t("meta_description")} />
            <meta name="Keywords" content={t("meta_keywords")} />
            <link rel="schema.DC" href="http://purl.org/dc/elements/1.1/" />
            <meta name="DC.title" content={t("home_title")} />
            <meta name="DC.Description" content={t("meta_description")} />
            <meta name="DC.Subject" content={t("meta_keywords")} />
            <meta name="DC.format" content="text/html" />
            <meta name="DC.language" scheme="RFC3066" content={ i18n.language === 'fr' ?  "fr-ca" : "en-ca"} />
            <meta name="Author" content={t("company_title")} />
            <meta name="Copyright" content={t("company_title")} />
            <meta name="Revisit-After" content="10 days" />
            <meta name="robots" content="all, noodp, noydir" />s
            <meta name="Date-Revision-yyyymmdd" content="20240202" />
            <meta content={i18n.language} http-equiv="content-language" />

            <link href={"https://vforge.ca/"} title={fr.company_title + " - " + fr.home_title} rel="start" />
            <link href={"https://vforge.ca/"} title={en.company_title + " - " + en.home_title} rel="next" />
        </Helmet>

        <header id="header" className="App-header" >
        <div className="App-header-burger" style={{visibility: "hidden"}} >
            <img className="App-header-burger-icon red" src={burger_red} alt='menu' />
          </div>
          <div className="App-header-menu" >
            <div className="App-menu-text" onClick={() => jumpToReleventDiv('technology')} >{t("menu_tech")}</div>
            <div className="App-menu-text" onClick={() => jumpToReleventDiv('grils')} >{t("menu_grils")}</div>
            <div className="App-menu-text" onClick={() => jumpToReleventDiv('contact')} >{t("menu_contact")}</div>
          </div>
          <img className={logoZoomed ? "App-logo-zoom" : "App-logo"} src={logo} alt='logo' />
          <div className="App-header-menu" >
            <div className="App-menu-text" onClick={() => jumpToReleventDiv('contact')} >{t("menu_dist")}</div>
            <div className="App-menu-text" onClick={() => jumpToReleventDiv('innovation')} >{t("menu_about")}</div>
            <div className="App-menu-text" onClick={() => changeLang()} >{t("menu_lang")}</div>
          </div>
          <div className="App-header-burger" >
            <img className="App-header-burger-icon red" src={burger_red} alt='menu' onClick={() => showSmallMenu()} />
          </div>
        </header>

        <div id="menu" className="Small-menu">
        <div className="Small-menu-close" onClick={() => hideSmallMenu()}>X</div>
          <div className='Small-menu-text-column'>
            <div className="Small-menu-text" onClick={() => clicSmallMenu('technology')} >{t("menu_tech")}</div>
            <div className="Small-menu-text" onClick={() => clicSmallMenu('grils')} >{t("menu_grils")}</div>
            <div className="Small-menu-text" onClick={() => clicSmallMenu('contact')} >{t("menu_contact")}</div>
            <div className="Small-menu-text" onClick={() => clicSmallMenu('contact')} >{t("menu_dist")}</div>
            <div className="Small-menu-text" onClick={() => clicSmallMenu('innovation')} >{t("menu_about")}</div>
            <div className="Small-menu-text" onClick={() => clicSmallMenu('lang')} >{t("menu_lang")}</div>
          </div>
        </div>

        <div className="App-intro">
          <h1 className='App-size lg'>{getText(t("intro_title"))}</h1>
          <h1 className='App-size sm'>{getText(t("intro_title_sm"))}</h1>
          <div className="App-button" onClick={() => jumpToReleventDiv('contact')}>{t("common_more")}</div>
        </div>
        <div>
          <Technology />
        </div>
        <div id="grils" ></div>
        <div className="Nomad-margin">
          <Nomad  />
        </div>
        <div className="Ragnar-margin">
          <Ragnar  />
        </div>
        <div className="Odin-margin">
          <Odin  />
        </div>
        <div >
          <Acc  />
        </div>
        <div id="contact"></div>
        <div >
          <Contact  />
        </div>
        <div id="innovation">
          <Innovation  />
        </div>
        <div >
          <Footer />
        </div>
      </div>
     </HelmetProvider>
  );
}

export default Home;
