import React from "react";
import i18n from '../i18n';

export const jumpToReleventDiv = (id) => {
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    releventDiv.scrollIntoView({behavior: "smooth",  block: "start"});
    }

export const getText = (text) => {
        var array = text.split('<br />');
        return array.map((text, id) => <React.Fragment key={id}>{text}<br/></React.Fragment>)
      }

export const changeLang = () => {
    i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
    /*window.history.replaceState(null, null, i18n.language === 'fr' ? "/" : "/" + i18n.language)
    window.location.reload();*/
    window.location.href = "/" + i18n.language === "fr" ? "" : i18n.language;
  }

export const setLang = () => {
  if(window.location.pathname === "/en" ? i18n.changeLanguage("en") : i18n.changeLanguage("fr"));
}