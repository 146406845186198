import '../css/products/odin.css';
import React from "react";
import { withTranslation } from "react-i18next";

class Odin extends React.Component {

  render() {
  return (
    <div  >
      <div className="App-size lg">
        <div className="App-footer">1 877 863-5885 — 147 chemin des Raymond, Rivière-du-Loup, QC Canada G5R 5X9</div>
      </div>
      <div className="App-size sm">
        <div className ="App-footer" ><span className ="App-footer-phone">1 877 863-5885</span><br />147 chemin des Raymond, Rivière-du-Loup, QC Canada G5R 5X9</div>
      </div>
    </div>
  );
}
}
export default withTranslation()(Odin);
