import '../css/app.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { getText } from "./functions";

import pizza1 from '../pics/pizza1.png';
import pizza2 from '../pics/pizza2.png';
import dessert from '../pics/dessert.png';
import baskets from '../pics/baskets.png';

class Accessories extends React.Component {

  render() {
  return (
    <div>
      <div className="App-size lg">
        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start" }}>
          <div className="Acc" >
            <div className='Acc-title'>{getText(this.props.t("acc_title"))}</div>
            <div className='Acc-desc'>{getText(this.props.t("acc_desc"))}</div>
          </div>
          <div className="Acc-pics">
              <img width="25%" className="Acc-pic" src={pizza1} alt='v-forge_pizzaOven_1' />
              <img width="30%" className="Acc-pic" src={pizza2} alt='v-forge_pizzaOven_2' />
              <img width="25%" className="Acc-pic" src={baskets} alt='v-forge_baskets' />
              <img width="25%" className="Acc-pic" src={dessert} alt='v-forge_dessert' />
          </div>
        </div>
      </div>
      <div className="App-size sm">
        <div className="Acc" >
          <div className='Acc-title'>{getText(this.props.t("acc_title"))}</div>
          <div className='Acc-desc'>{getText(this.props.t("acc_desc"))}</div>
          <div className="Acc-pics">
            <div className="Acc-pics-row">
              <img width="45%" className="Acc-pic" src={pizza1} alt='v-forge_pizzaOven_1' />
              <img width="55%" className="Acc-pic" src={pizza2} alt='v-forge_pizzaOven_2' />
            </div>
            <div className="Acc-pics-row">
              <img width="50%" className="Acc-pic" src={baskets} alt='v-forge_baskets' />
              <img width="50%" className="Acc-pic" src={dessert} alt='v-forge_dessert' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}
export default withTranslation()(Accessories);
