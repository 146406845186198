import '../css/app.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { getText } from "./functions";

import technical_info from '../pics/technical illustration.png';

import icon_chimney from '../svg/chimney.svg';
import icon_construction from '../svg/construction.svg';
import icon_money from '../svg/money.svg';
import icon_thermostat from '../svg/thermostat.svg';

class Technology extends React.Component {

  render() {
  return (
    <div>
        <div id="technology"></div>
          <div className="App-technology">
            <h2 className='App-size lg'>{getText(this.props.t("tech_title"))}</h2>
            <h2 className='App-size sm'>{getText(this.props.t("tech_title_sm"))}</h2>
            <div className="App-tech-desc" >{getText(this.props.t("tech_desc"))}</div>
          </div>
          <div className="App-size lg">
            <div style={{display: "flex", flexDirection: "row", width: "80vw", alignSelf: "center", justifyContent: "space-evenly"}}>
              <div className="App-icons">
                <div className="App-icon"> 
                  <img  className="App-icon-size" src={icon_chimney} alt='v-forge-chimney' />
                  <div className="App-icon-text">{getText(this.props.t("icon_chimney"))}</div>
                </div>
                <div className="App-icon"> 
                  <img  className="App-icon-size" src={icon_construction} alt='v-forge-construction' />
                  <div className="App-icon-text">{getText(this.props.t("icon_construction"))}</div>
                </div>
              </div>
              <img  className= "Tech-img" src={technical_info} alt='v-forge-technical_illustration' />
              <div className="App-icons">
                <div className="App-icon"> 
                  <img  className="App-icon-size" src={icon_thermostat} alt='v-forge-thermostat' />
                  <div className="App-icon-text">{getText(this.props.t("icon_thermostat"))}</div>
                </div>
                <div className="App-icon"> 
                  <img  className="App-icon-size" src={icon_money} alt='v-forge-money' />
                  <div className="App-icon-text">{getText(this.props.t("icon_money"))}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="App-size sm">
            <div>
              <img  className= "Tech-img" src={technical_info} alt='v-forge-technical_illustration' />
            </div>  
            <div className="App-icons">
              <div className="App-icon"> 
                <img  className="App-icon-size" src={icon_construction} alt='v-forge-construction' />
                <div className="App-icon-text">{getText(this.props.t("icon_construction"))}</div>
              </div>
              <div className="App-icon"> 
                <img  className="App-icon-size" src={icon_money} alt='v-forge-money' />
                <div className="App-icon-text">{getText(this.props.t("icon_money"))}</div>
              </div>
              <div className="App-icon"> 
                <img  className="App-icon-size" src={icon_chimney} alt='v-forge-chimney' />
                <div className="App-icon-text">{getText(this.props.t("icon_chimney"))}</div>
              </div>
              <div className="App-icon"> 
                <img  className="App-icon-size" src={icon_thermostat} alt='v-forge-thermostat' />
                <div className="App-icon-text">{getText(this.props.t("icon_thermostat"))}</div>
              </div>
              
            </div>
          </div>
    </div>
  );
}
}
export default withTranslation()(Technology);
